import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../../components'
import { SearchResultI } from '../../../components/ComponentInterface'
import i18n from '../../../i18n'
import { getDobYear } from '../../../utils'
import { useContext } from '../../../context'
import { formatName } from '../utils'
import { ReactComponent as Hamburger } from '../../../asset/icon-hamb.svg'
import { AccountStatusCell, VerificationStatusCell } from './StatusCell'
import { TableBodyRows } from './AccountDetails/TableBodyRows'
import { EmptyState } from './AccountDetails/EmptyState'
import ActionDropdown from './common/ActionDropdown'
import AccountInfo from './common/AccountInfo'

type SearchResultTableProps = {
  data: SearchResultI[]
  emptyMessage?: string
  searchingError?: boolean
  emptyBtnAction?: () => void
  validationBrokerData?: any
  handleEditClick?: (key: string) => void
}

const SearchResultTable = ({
  data,
  emptyMessage,
  searchingError,
  emptyBtnAction,
  validationBrokerData,
  handleEditClick = () => {},
}: SearchResultTableProps) => {
  const {
    state: { profile },
  } = useContext()
  const permissions = profile?.permissions || []

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev)

  const firstItem = data[0] || {}

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false)
    }
  }

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }

    return () => document.removeEventListener('click', handleClickOutside)
  }, [isDropdownOpen])

  return (
    <div className="search-table">
      <div className="table-header">
        <div className="table-title">
          <h2 className="title">{i18n.t('SearchPage.title.AccountDetails')}</h2>
        </div>
      </div>
      <div className="table-header-info">
        <div className="account-container">
          <AccountInfo title={i18n.t('SearchPage.SearchLabels.id_card_number')} value={firstItem.id_card_number} />
          <AccountInfo
            title={i18n.t('SearchPage.SearchLabels.account_status')}
            value={firstItem.account_status && <AccountStatusCell accountStatus={firstItem.account_status} />}
          />
        </div>
        <div className="account-container">
          <AccountInfo title={i18n.t('SearchPage.SearchLabels.email')} value={firstItem.email} />
          <AccountInfo
            title={i18n.t('SearchPage.SearchLabels.verification_status')}
            value={
              firstItem.verification_status && (
                <VerificationStatusCell verificationStatus={firstItem.verification_status} />
              )
            }
          />
        </div>
        <div className="account-container">
          <AccountInfo
            title={i18n.t('SearchPage.SearchLabels.name')}
            value={formatName(firstItem.first_name, firstItem.last_name)}
          />
        </div>
        <div className="account-container">
          <AccountInfo
            title={i18n.t('SearchPage.SearchLabels.year_of_birth')}
            value={firstItem.birth_date && getDobYear(firstItem.birth_date)}
          />
        </div>
        <div className="action-button" ref={dropdownRef}>
          <Button
            className="border-btn"
            onClick={toggleDropdown}
            text={
              <div className="input-action">
                {i18n.t('actions')}
                <div className="icon">
                  <Hamburger />
                </div>
              </div>
            }
            dataTestId="button-account-actions"
          />
          <ActionDropdown isOpen={isDropdownOpen} />
        </div>
      </div>
      <table data-testid="results-table">
        <tbody>
          {data.length ? (
            <TableBodyRows data={data} validationBrokerData={validationBrokerData} handleEditClick={handleEditClick} />
          ) : (
            !searchingError && (
              <tr>
                <td className="empty-row"></td>
              </tr>
            )
          )}
        </tbody>
      </table>
      {searchingError && !data.length && (
        <EmptyState emptyMessage={emptyMessage} permissions={permissions} emptyBtnAction={emptyBtnAction} />
      )}
    </div>
  )
}

export default SearchResultTable
