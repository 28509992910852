import { useTriggeredDeactivateAccount } from '../../../services/AdminServices'

export default function useDeactiveAccount(userId: string) {
  const { postDeactiveAccount, isLoading } = useTriggeredDeactivateAccount(userId)

  const handleDeactiveAccount = async (reason) => {
    try {
      await postDeactiveAccount({ data: { reason } })
    } catch (e) {}
  }

  return { handleDeactiveAccount, isLoading }
}
