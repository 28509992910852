import { useTriggeredArchiveAccount } from '../../../services/AdminServices'

export default function useArchiveAccount(userId: string) {
  const { postArchiveAccount, isLoading } = useTriggeredArchiveAccount(userId)

  const handleArchiveAccount = async (reason) => {
    try {
      await postArchiveAccount({ data: { reason } })
    } catch (e) {}
  }

  return { handleArchiveAccount, isLoading }
}
