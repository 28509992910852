import React, { useState, useCallback } from 'react'
import i18n from '../../i18n'
import { ReactComponent as ArrowBottom } from '../../asset/icon-arrow-bottom.svg'
import { ReactComponent as ArrowTop } from '../../asset/icon-arrow-top.svg'
import { ReactComponent as Alert } from '../../asset/icon-alert-red.svg'

interface Option {
  value: string
  label: string
}

interface SelectInputProps {
  value: string
  onChange: (value: string) => void
  options: Option[]
  label?: string
  type?: string
  required?: boolean
  isError?: boolean
  labelDescription?: string
  isFocused?: boolean
  className?: string
  errorMessage?: string
  dataTestId?: string
  errorDataTestId?: string
}

const SelectInput: React.FC<SelectInputProps> = ({
  value,
  onChange,
  options,
  label = '',
  required = false,
  isError = false,
  labelDescription = '',
  isFocused = false,
  className = '',
  errorMessage = '',
  dataTestId,
  errorDataTestId,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(event.target.value)
      setIsOpen(false)
    },
    [onChange],
  )

  return (
    <div className={`textbox-form ${className}`}>
      {label && (
        <label htmlFor={label} className="textbox-form-label">
          <strong>{label}</strong> {labelDescription}
          {required && <strong style={{ color: '#9d3427' }}>*</strong>}
        </label>
      )}

      {isError && (
        <div
          data-testid={errorDataTestId}
          style={{ display: 'flex', color: '#9d3427', fontSize: '14px', marginBottom: '8px' }}
        >
          <Alert />
          <p>{i18n.t('required-field')}</p>
        </div>
      )}

      <div
        className={`textbox-form-input 

          ${isError ? 'error' : ''} 
          ${isFocused && !isError ? 'active-border' : ''}`}
      >
        <select
          id={label}
          value={value || 'default'}
          onChange={handleChange}
          onFocus={() => setIsOpen(true)}
          onBlur={() => setIsOpen(false)}
          data-testid={dataTestId}
          aria-invalid={isError}
        >
          <option value="default" disabled data-testid={'default'}>
            {i18n.t('select-default')}
          </option>
          {options.map(({ value, label }) => (
            <option key={value} value={value} data-testid={label.toLowerCase().split(' ').join('-')}>
              {label}
            </option>
          ))}
        </select>

        <div className="arrow-icon">{isOpen ? <ArrowTop /> : <ArrowBottom />}</div>
        {isError && errorMessage && (
          <p className="error-msg" data-testid={errorDataTestId}>
            {i18n.t(errorMessage)}
          </p>
        )}
      </div>
    </div>
  )
}

export default React.memo(SelectInput)
