import React from 'react'
import i18n from '../../../i18n'
import { PersonHistoryRequest } from '../../../components/ComponentInterface'
import { ReactComponent as ArrowNext } from '../../../asset/icon-arrow-next.svg'
import { ReactComponent as ArrowNextDisabled } from '../../../asset/icon-arrow-next-disabled.svg'
import { ReactComponent as ArrowBack } from '../../../asset/icon-arrow-back.svg'
import { ReactComponent as ArrowBackDisabled } from '../../../asset/icon-arrow-back-disabled.svg'
import { USER_HISTORY_PAGE_SIZE } from '../../../services/AdminServices'

interface IAccountHistoryProps {
  history: PersonHistoryRequest
  setPage: (page: number) => void
  page: number
  isLoading: boolean
}

export default function AccountHistoryTable({ history, page, setPage }: IAccountHistoryProps) {
  const { data, total_elements, total_pages } = history

  const handleNextPage = () => {
    if (page < total_pages - 1) {
      setPage(page + 1)
    }
  }

  const handleBackPage = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const startIndex = page * USER_HISTORY_PAGE_SIZE + 1
  const endIndex = Math.min((page + 1) * USER_HISTORY_PAGE_SIZE, total_elements)

  return (
    <div className="account-history-container" data-testid="account-history-table">
      <div className="search-table">
        <div className="table-header">
          <div className="table-title">
            <h2 className="title">{i18n.t('SearchPage.title.AccountHistory')}</h2>
          </div>
        </div>

        <table className="account-history-table" data-testid="account-history-table">
          <thead>
            <tr>
              <th style={{ borderRight: '1px solid #CCCCCC' }} data-testid="head-column-Date">
                {i18n.t('SearchPage.account-history.Date')}
              </th>
              <th style={{ borderRight: '1px solid #CCCCCC' }} data-testid="head-column-Action">
                {i18n.t('SearchPage.account-history.Action')}
              </th>
              <th style={{ borderRight: '1px solid #CCCCCC' }} data-testid="head-column-Admin">
                {i18n.t('SearchPage.account-history.Admin')}
              </th>
              <th data-testid="head-column-Notes">{i18n.t('SearchPage.account-history.Notes')}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map(({ administrator, date, details, event_type }, index) => (
              <tr key={`body-row-${index}`}>
                <td>{date}</td>
                <td>{event_type}</td>
                <td>{administrator}</td>
                <td style={{ maxWidth: '418px', overflowWrap: 'break-word' }}>{details?.reason || '--'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div>{`${startIndex} - ${endIndex} of ${total_elements} actions`}</div>
        <div className="actions">
          <div className="btn-page" onClick={handleBackPage} data-testId="account-history-table-back-btn">
            {page === 0 ? <ArrowBackDisabled /> : <ArrowBack />}
          </div>
          <div onClick={handleNextPage} data-testId="account-history-table-next-btn">
            {page + 1 === total_pages ? <ArrowNextDisabled /> : <ArrowNext />}
          </div>
        </div>
      </div>
    </div>
  )
}
