import React, { FC, useEffect, useState } from 'react'
import parse from 'html-react-parser'
import ReactModal from 'react-modal'
import i18n from '../../../../i18n'
import { ReactComponent as Close } from '../../../../asset/icon-close-white.svg'
import { Button, TextInput } from '../../../../components'

import SelectInput from '../../../../components/inputs/SelectInput'
import useReactiveAccount from '../../hooks/useReactiveAccount'

type ReactiveAccountModallProps = {
  isOpen: boolean
  close: () => void
  userId?: string
  onFinish: () => void
}

const ReactiveAccountModal: FC<ReactiveAccountModallProps> = ({ isOpen, close, userId, onFinish }) => {
  const { handleReactiveAccount, isLoading } = useReactiveAccount(userId || '')
  const [adicionalNotes, setAdicionalNotes] = useState('')
  const [selectedReason, setSelectedReason] = useState('')
  const [isError, setIsError] = useState(false)
  const [isOptionReason, setIsOptionReason] = useState(false)

  useEffect(() => {
    if (isError && selectedReason) {
      setIsError(false)
    }
  }, [selectedReason, isError])

  const handleAdditionalNotes = (e) => {
    const { value } = e?.target
    setAdicionalNotes(value)
  }

  const handleSelectChange = (value) => {
    setSelectedReason(value)
  }

  const onClose = () => {
    setAdicionalNotes('')
    setSelectedReason('')
    setIsError(false)
    setIsOptionReason(false)

    if (close) close()
  }

  const handleDeactiveWithReason = async () => {
    if (!selectedReason) {
      setIsError(true)
      return
    }

    if (selectedReason === 'Other' && adicionalNotes === '') {
      setIsOptionReason(true)
      return
    } else setIsOptionReason(false)

    const reasonWithNotes = `${selectedReason}${!!adicionalNotes ? `. ${adicionalNotes}` : ''}`

    await handleReactiveAccount(reasonWithNotes)
    await onFinish()
    onClose()
  }

  const options = [
    {
      value: i18n.t('SearchPage.ReactivateAccount.reason-options.no-longer'),
      label: i18n.t('SearchPage.ReactivateAccount.reason-options.no-longer'),
    },
    {
      value: i18n.t('SearchPage.ReactivateAccount.reason-options.duplicate'),
      label: i18n.t('SearchPage.ReactivateAccount.reason-options.duplicate'),
    },
    {
      value: i18n.t('SearchPage.ReactivateAccount.reason-options.security'),
      label: i18n.t('SearchPage.ReactivateAccount.reason-options.security'),
    },
    {
      value: i18n.t('SearchPage.ReactivateAccount.reason-options.other'),
      label: i18n.t('SearchPage.ReactivateAccount.reason-options.other'),
    },
  ]

  return (
    <ReactModal overlayClassName="ReactModal__Overlay" isOpen={isOpen} className="modal reset-2fa-modal">
      <div className="header" data-testid={'reactivate-account-modal'}>
        <h2>{i18n.t('SearchPage.ReactivateAccount.title')}</h2>
        <button data-testid="reactivate-account-close" onClick={onClose}>
          <Close />
        </button>
      </div>
      <div className="content">
        <p>{parse(i18n.t('SearchPage.ReactivateAccount.description'))}</p>
        <div>
          <SelectInput
            value={selectedReason}
            onChange={handleSelectChange}
            options={options}
            label={i18n.t('SearchPage.ReactivateAccount.reason')}
            required
            dataTestId={'reason-for-reactivate'}
            isError={isError}
            errorDataTestId={'required-field-error'}
          />
          <TextInput
            name="aditional-notes"
            onChange={handleAdditionalNotes}
            value={adicionalNotes}
            label={i18n.t('SearchPage.ReactivateAccount.notes')}
            dataTestId={'aditional-notes'}
            errorDataTestId={'aditional-notes-error'}
            isError={isOptionReason}
            errorMessage={i18n.t('required-field')}
          />
        </div>
        <div className="actions">
          <Button dataTestId="reactivate-account-cancel" onClick={onClose} text={i18n.t('cancel')} />
          <Button
            dataTestId="reactivate-account-action"
            onClick={handleDeactiveWithReason}
            className="border-btn"
            isLoading={isLoading}
            text={i18n.t('SearchPage.ReactivateAccount.btn-action')}
          />
        </div>
      </div>
    </ReactModal>
  )
}

export default ReactiveAccountModal
