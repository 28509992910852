import React, { FC, useEffect, useMemo, useState } from 'react'
import ReactModal from 'react-modal'
import { ReactComponent as Close } from '../../../../asset/icon-close-white.svg'
import { Button } from '../../../../components'
import SelectInput from '../../../../components/inputs/SelectInput'
import i18n from '../../../../i18n'
import { InformationRemovalType } from '../../../../common/enum'
import parse from 'html-react-parser'
import { useTriggeredUnlinkHealthInfo, useTriggeredUnlinkVerifiedInfo } from '../../../../services/AdminServices'

type UnlinkVerifiedInfoModalProps = {
  userId?: string
  isOpen: boolean
  close: () => void
  options: Array<InformationRemovalType>
}

const UnlinkVerifiedInfoModal: FC<UnlinkVerifiedInfoModalProps> = ({ userId, isOpen, close, options }) => {
  const [informationType, setInformationType] = useState<InformationRemovalType | null>(null)
  const { unlinkVerifiedInfo, isLoading: isUnlinkVerifiedInfoLoading } = useTriggeredUnlinkVerifiedInfo(userId || '')
  const { unlinkHealthInfo, isLoading: isUnlinkHealthInfoLoading } = useTriggeredUnlinkHealthInfo(userId || '')
  const [showError, setShowError] = useState<boolean>(false);

  const isLoading = useMemo(
    () => isUnlinkVerifiedInfoLoading || isUnlinkHealthInfoLoading,
    [isUnlinkVerifiedInfoLoading, isUnlinkHealthInfoLoading],
  )

  const handleSelectChange = (value: string) => {
    setInformationType(value as InformationRemovalType)
  }

  const optionsWithLabel = useMemo(
    () =>
      options.map((option) => ({
        value: option,
        label: i18n.t(option),
      })),
    [options],
  )

  useEffect(()=>{
    setShowError(false)
  },[informationType])

  /**
   * Reset the state before closing the modal
   */
  const handleClose = () => {
    setShowError(false)
    setInformationType(null)
    close()
  }

  /**
   * Call the proper unlink function based on the selected information type and then close the modal
   */
  const unlink = async () => {
    try {
      if (informationType === InformationRemovalType.HealthInformation) {
        await unlinkHealthInfo()
      } else if (informationType === InformationRemovalType.AllVerifiedInformation) {
        await unlinkVerifiedInfo()
      }
    } catch (e) {
      console.error('Error when unlinking verified info: ', e)
    } finally {
      if(!!informationType) handleClose()
      else setShowError(true)
    }
  }

  return (
    <ReactModal
      overlayClassName="ReactModal__Overlay"
      isOpen={isOpen}
      className="modal unlink-verified-info-modal"
      testId="unlink-verified-info-modal"
    >
      <div className="header">
        <h2>{i18n.t('SearchPage.UnlinkVerifiedInfo.title')}</h2>
        <button data-testid="unlink-verified-info-close" onClick={handleClose}>
          <Close />
        </button>
      </div>
      <div className="content">
        <p>{parse(i18n.t('SearchPage.UnlinkVerifiedInfo.description'))}</p>
        <div>
          <SelectInput
            value={informationType || ''}
            onChange={handleSelectChange}
            options={optionsWithLabel}
            label={i18n.t('SearchPage.UnlinkVerifiedInfo.type')}
            required
            dataTestId="unlink-verified-info-type-select"
            isError={showError}
            errorDataTestId='unlink-verified-info-type-error'
          />
        </div>
        <div className="actions">
          <Button dataTestId="unlink-verified-info-cancel" onClick={handleClose} text={i18n.t('cancel')} />
          <Button
            dataTestId="unlink-verified-info-action"
            onClick={unlink}
            className="border-btn"
            isLoading={isLoading}
            text={i18n.t('SearchPage.UnlinkVerifiedInfo.btn-action')}
          />
        </div>
      </div>
    </ReactModal>
  )
}

export default UnlinkVerifiedInfoModal
