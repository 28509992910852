import React, { FC } from 'react'
import ReactModal from 'react-modal'
import i18n from '../../i18n'
import Button from '../buttons/Button'
import parse from 'html-react-parser'

type LimitedPermissionsModalProps = {
  isOpen: boolean
  close: () => void
}

const LimitedPermissionsModal: FC<LimitedPermissionsModalProps> = ({ isOpen, close }) => {
  return (
    <ReactModal testId="limited-permissions" overlayClassName="ReactModal__Overlay" isOpen={isOpen} className="modal limited-permissions-modal">
      <div className="header">
        <h2>{parse(i18n.t('limited-permissions'))}</h2>
      </div>
      <div className="content">
        <p>{parse(i18n.t('limited-permissions-description'))}</p>
        <div className="actions">
          <Button dataTestId="limited-permissions-close" onClick={close} text={i18n.t('okay')} />
        </div>
      </div>
    </ReactModal>
  )
}

export default LimitedPermissionsModal
