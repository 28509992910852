export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  PLACEHOLDER = 'PLACEHOLDER',
  DEACTIVATED = 'DEACTIVATED',
  INACTIVE = 'INACTIVE',
}

export enum VerificationStatus {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
  PENDING_REVIEW = 'PENDING_REVIEW',
}

export enum AccountPermission {
  canViewAccountDetails = 'PEI_IDS_CAN_VIEW_ACCOUNT_DETAILS',
  canFetchSWATData = 'PEI_IDS_CAN_FETCH_ACCOUNT_SWAT_DETAILS',
  canViewAccountHistory = 'PEI_IDS_CAN_VIEW_ACCOUNT_HISTORY',
  canEditAccountDetails = 'PEI_IDS_CAN_EDIT_ACCOUNT_DETAILS',
  canVerifyAccount = 'PEI_IDS_CAN_VERIFY_ACCOUNT',
  canAddCR = 'PEI_IDS_CAN_ADD_CR',
  canRemoveSWAT = 'PEI_IDS_CAN_REMOVE_SWAT',
  canRemoveCR = 'PEI_IDS_CAN_REMOVE_CR',
  canReset2FA = 'PEI_IDS_CAN_RESET_2FA',
  canChangeEmail = 'PEI_IDS_CAN_CHANGE_EMAIL',
  canDeactivateAccount = 'PEI_IDS_CAN_DEACTIVATE_ACCOUNT',
  canViewDeactivationDetails = 'PEI_IDS_CAN_VIEW_DEACTIVATION',
  canArchiveAccount = 'PEI_IDS_CAN_ARCHIVE_ACCOUNT',
  canReActivateAccount = 'PEI_IDS_CAN_REACTIVATE_ACCOUNT',
}

export enum InformationRemovalType {
  AllVerifiedInformation = 'SearchPage.UnlinkVerifiedInfo.typeOptions.allVerifiedInfo',
  HealthInformation = 'SearchPage.UnlinkVerifiedInfo.typeOptions.healthInfo',
}
